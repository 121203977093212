import { useSelector, useDispatch } from 'react-redux';
import { Redux } from 'stores/interfaces';
import * as middleware from './middleware';
import { InterceptDistributorRemovalProps, PosReducer, PosParams } from './interfaces';
import useDialog from 'stores/dialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleSetFidelityData } from 'stores/fidelity/middleware';
import { BusinessUnitResponse } from 'pages/pos/Distributors/interfaces';
import useBasket from 'stores/basket';
import { CustomError } from 'shared/errors';
import { useEffect } from 'react';

export default function usePos() {
	const dispatch = useDispatch();
	const posStore = useSelector((stores: Redux) => stores.posReducer);

	const navigate = useNavigate();
	const location = useLocation();
	const { handleOpenDialog } = useDialog();

	const { handleGetBasketData, basketStore } = useBasket();

	const handleSetHasDistributor = (hasDistributor: boolean) => {
		dispatch(middleware.handleSetHasDistributor(hasDistributor));
	};

	const setPosRegisterData = (data: string) => {
		dispatch(middleware.handleManagePosRegisterData('SET', data));
	};

	const clearPosRegisterData = () => {
		dispatch(middleware.handleManagePosRegisterData('CLEAR'));
	};

	const interceptDistributorRemoval = async ({ distributorId, businessUnitId, basketReference, onConfirm, onSeeTheCart, onAfterConfirm, onAfterRejected }: InterceptDistributorRemovalProps) => {
		await new Promise(async (resolve, reject) => {
			const onResolve = () => resolve({ distributorId, businessUnitId })
			const onReject = () => reject(new CustomError('', 'open-cart'))

			try {
				const baskets = basketReference || basketStore.basket.baskets;

				const hasDistributorInBasket = baskets.some(basket => basket.businessUnitId === businessUnitId && basket.distributorId === distributorId);

				if (hasDistributorInBasket) {
					await handleOpenDialog({
						heading: {
							title: 'Deseja continuar?',
						},
						content: {
							description:
								'Atenção! Existem produtos em seu carrinho do distribuidor alterado, o mesmo será excluído. Deseja continuar?',
						},
						footer: {
							primaryButton: {
								label: 'Ver carrinho',
								async onClick() {
									onSeeTheCart?.()
									onReject()
								},
							},
							secondaryButton: {
								label: 'Confirmar',
								async onClick() {
									onConfirm?.()
									onResolve()
								},
							},
						},
					});
				}

				onAfterConfirm?.()
				onResolve()
			} catch {
				onAfterRejected?.()
				onReject()
			}
		})
	}

	return {
		posStore,
		selectedPos: posStore.myPos.find(({ isSelected }) => isSelected),
		setPosRegisterData,
		clearPosRegisterData,
		handleSetMyPos: (pos: PosReducer['myPos']) => dispatch(middleware.handleSetMyPos(pos)),
		handleIncrementToMyPos: (pos: PosReducer['myPos']) => {
			const objectify = (list: PosParams[]) => list
				.reduce((acc, item) => ({ ...acc, [item.pointOfSaleId]: item }), {} as Record<ObjectID, PosParams>);

			const currentPos = objectify(posStore.myPos);
			const camePos = objectify(pos);

			const mergedAndUpdated = Object
				.entries(camePos)
				.reduce((acc, [posId, camePosData]) => ({ ...acc, [posId]: { ...(acc?.[posId] || {}), ...camePosData } }), currentPos)

			return dispatch(middleware.handleSetMyPos(Object.values(mergedAndUpdated)));
		},
		handleSetPosLoading: (posId: PosReducer['loading']) => dispatch(middleware.handleSetPosLoading(posId)),
		handleCleanPos: () => dispatch(middleware.handleSetCleanPos()),
		handleClearSelectedPos: () => dispatch(middleware.handleClearSelectedPos()),
		handleSetPosDistributors: (distributors: Concrete<PosReducer['selectedPos']['distributors']>) =>
			dispatch(middleware.handleSetPosDistributors(distributors)),
		handleSelectPos: async (posId: string, isDistributorsPage?: boolean, openSideBasket?: boolean) =>
			await dispatch(
				middleware.handleSetSelectedPos(
					posId,
					handleSetFidelityData,
					handleGetBasketData,
					handleOpenDialog,
					navigate,
					location,
					isDistributorsPage,
					openSideBasket,
				),
			),
		handleUpdateDistributor: (distributor: BusinessUnitResponse) =>
			dispatch(middleware.handleSetUpdateDistributor(distributor)),
		handleSetHasDistributor,
		interceptDistributorRemoval
	};
}
